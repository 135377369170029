import styled from 'styled-components';
import _Modal from '../Modal';

export const Modal = styled(_Modal)`
  position: absolute;
  inset: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;

  &.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }

  & .${_Modal.CONTENT_CLASS_NAME} {
    position: absolute;
    inset: 0;
    background-color: #ffffff;
    outline: none;
    overflow: auto;

    @media (min-width: 700px) {
      margin: 0 auto;
      width: 400px;
      height: min-content;
      inset: 50% 0 0 0;
      transform: translateY(-50%);
      border-radius: 8px;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  padding: 20px;
`;

export const BoxTitle = styled.div`
  padding: 20px;
  border-radius: 8px 8px 0 0;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.colors.contrastText};
  background-color: ${({ theme }) => theme.colors.danger};
`;
