import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import FestivalProvider from './contexts/FestivalContext';
import ErrorModal, { ErrorProvider } from './components/ErrorModal';
import GlobalStyles from './GlobalStyles';
import MainRoutes from './routes';
import theme from './theme';

const queryClient = new QueryClient();

const App = () => (
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <FestivalProvider>
          <ErrorProvider>
            <MainRoutes />
            <GlobalStyles />
            <ErrorModal />
          </ErrorProvider>
        </FestivalProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </BrowserRouter>
);

export default App;
