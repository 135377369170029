import React from 'react';
import Section, { AsyncSection } from '../../components/Section';
import { DishesSwiper } from '../../components/Swiper';
import { ContentCategory } from '../../api/types';
import { useFestival } from '../../contexts/FestivalContext';
import { PageDescription, PageTitle } from '../styles';
import { Divider, MoreDishes, PageWrapper, MoreDishesLink } from './styles';

const Home = () => {
  const { festival } = useFestival();

  return (
    <PageWrapper>
      <PageTitle>
        <div>Pratos Participantes</div>
        <PageDescription>
          Confira abaixo os pratos e restaurantes participantes e vote no seu favorito!
        </PageDescription>
      </PageTitle>
      <DishesSwiper />
      <MoreDishes>
        <Divider />
        <MoreDishesLink filled to="/pratos">
          + ver todos os pratos participantes
        </MoreDishesLink>
        <Divider />
      </MoreDishes>
      <AsyncSection category={ContentCategory.attraction}>
        {({ name, content, image }) => (
          <Section>
            <Section.Title color={festival!.data.attributes.third_color}>{name}</Section.Title>
            <Section.Paragraph>{content}</Section.Paragraph>
            <Section.Link filled to="/programacao">
              + ver toda a programação
            </Section.Link>
            <Section.Image src={image} alt="Imagem da programação" />
          </Section>
        )}
      </AsyncSection>
      <AsyncSection category={ContentCategory.about}>
        {({ name, content, image }) => (
          <Section>
            <Section.Image src={image} alt="Imagem do festival" />
            <Section.Title color={festival!.data.attributes.first_color}>{name}</Section.Title>
            <Section.Paragraph>{content}</Section.Paragraph>
            <Section.Link filled to="/festival">
              + ver mais informações
            </Section.Link>
          </Section>
        )}
      </AsyncSection>
    </PageWrapper>
  );
};

export default Home;
