import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  overflow-x: hidden;
  height: 100%;
`;

export const Body = styled.div<{ slide: boolean; zIndex: number }>`
  position: relative;
  min-height: 100%;
  z-index: ${({ zIndex, slide }) => (slide ? 0 : zIndex)};
`;
