import styled from 'styled-components';

export const RadioButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const Radio = styled.div<{ isSelected: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-bottom: 4px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primary : 'transparent'};

  @media (max-width: 450px) {
    width: 15px;
    height: 15px;
  }

  @media (max-width: 332px) {
    width: 10px;
    height: 10px;
  }
`;

export const RadioEmoji = styled.img`
  width: 20px;

  @media (max-width: 332px) {
    display: none;
  }
`;

export const PollSection = styled.li`
  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const PollName = styled.div`
  margin-bottom: 20px;
`;

export const List = styled.ol`
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-bottom: 20px;
`;
