import styled from 'styled-components';
import Card from '../../components/Card';
import { List as _List } from '../styles';

export const List = styled(_List)`
  & > *:not(:last-child) {
    margin-bottom: 15vh;
  }
`;

export const CardWrapper = styled(Card)`
  min-width: 330px;
`;

export const GroupImage = styled(Card.Image)`
  height: 280px;
`;
