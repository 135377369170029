import styled from 'styled-components';
import Card from '../../../components/Card';
import { ButtonsWrapper as _ButtonsWrapper } from '../styles';

export const CardWrapper = styled(Card)`
  align-items: center;
  width: 100%;
  max-width: 80%;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 20px 10px;
  background-color: ${({ theme }) => theme.colors.blue};
`;

export const Paragraph = styled(Card.Paragraph)`
  padding: 0;

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const SectionsWrapper = styled.div`
  flex: 1;
  margin-bottom: 70px;
  padding: 0 5px 10px;
  overflow: auto;

  @media (min-width: 300px) {
    padding: 0 20px 20px;
  }
`;

export const ButtonsWrapper = styled(_ButtonsWrapper)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;

  ::before {
    content: '';
    display: block;
    position: fixed;
    pointer-events: none;
    left: 0;
    right: 0;
    bottom: 70px;
    height: 50px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.13) -18.18%,
      rgba(0, 0, 0, 0.2) 5%,
      rgba(0, 0, 0, 0.13) 20%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`;
