import React from 'react';
import angryEmoji from '../../../../assets/images/angry-emoji.png';
import happyEmoji from '../../../../assets/images/happy-emoji.png';
import Flex from '../../../../components/Flex';
import { VoteState } from '..';
import { PollName, PollSection, Radio, RadioEmoji, RadioButton, List } from './styles';

type Props = {
  votes: VoteState[];
  onChange: (grade: number, vote: VoteState) => void;
};

const Questions = ({ votes, onChange }: Props) => {
  const list = votes.map((vote) => (
    <PollSection key={vote.question_id}>
      <PollName>{vote.description}</PollName>
      <Flex justifyContent="space-between">
        {Array.from({ length: 10 }, (_, i) => (
          <RadioButton key={i} onClick={() => onChange(i + 1, vote)}>
            <Radio isSelected={vote.grade === i + 1} />
            {i + 1}
            {i === 0 && <RadioEmoji src={angryEmoji} alt="angry reaction" />}
            {i === 9 && <RadioEmoji src={happyEmoji} alt="happy reaction" />}
          </RadioButton>
        ))}
      </Flex>
    </PollSection>
  ));

  return <List>{list}</List>;
};

export default Questions;
