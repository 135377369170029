import React from 'react';
import { FlexWrapper } from './styles';

export type Props = {
  justifyContent:
    | 'center'
    | 'space-between'
    | 'space-evenly'
    | 'space-around'
    | 'flex-start'
    | 'flex-end';
};

const Flex = (props: React.ComponentProps<typeof FlexWrapper> & Props) => (
  <FlexWrapper {...props} />
);

export default Flex;
