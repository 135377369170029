import React from 'react';
import { useQuery } from 'react-query';
import { getContent } from '../../api/festivalAPI';
import { Content, ContentCategory } from '../../api/types';
import { LoaderSection } from '../Loader';
import {
  Anchor,
  ImageWrapper,
  Link,
  LinkWrapper,
  Paragraph,
  SectionWrapper,
  Title,
} from './styles';

type AsyncSectionProps = {
  category: ContentCategory;
  children: (content: Content) => JSX.Element;
};

const loadingTranslations = {
  [ContentCategory.about]: 'Festival',
  [ContentCategory.attraction]: 'Programação',
  [ContentCategory.chef]: 'Chefe',
  [ContentCategory.meals]: 'Pratos',
  [ContentCategory.restaurants]: 'Restaurantes',
};

const Section = (props: React.ComponentProps<typeof SectionWrapper>) => (
  <SectionWrapper {...props} />
);

Section.Paragraph = ({
  bold,
  ...props
}: React.ComponentProps<typeof Paragraph> & { bold?: boolean }) => (
  <Paragraph {...props} $bold={bold} />
);

Section.Title = ({ color, ...props }: React.ComponentProps<typeof Title> & { color: string }) => (
  <Title {...props} $color={color} />
);

Section.Image = ({
  alt,
  ...props
}: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => (
  <ImageWrapper>
    <img {...props} alt={alt} />
  </ImageWrapper>
);

Section.Link = (props: React.ComponentProps<typeof Link>) => (
  <LinkWrapper>
    <Link {...props} />
  </LinkWrapper>
);

Section.Anchor = ({
  children,
  ...props
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => (
  <LinkWrapper>
    <Anchor {...props}>{children}</Anchor>
  </LinkWrapper>
);

export const AsyncSection = ({ category, children }: AsyncSectionProps) => {
  const { data, isLoading } = useQuery(`/content/${category}`, () => getContent(category));

  // Honestly, a skeleton would be a much better UI
  if (isLoading || !data) {
    return <LoaderSection height="450px" text={`Carregando ${loadingTranslations[category]}...`} />;
  }

  const [section] = data.data;
  return children(section.attributes);
};

export default Section;
