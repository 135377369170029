import styled from 'styled-components';

export const SponsorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 80px auto 0;
  width: 90%;
`;

export const SponsorSection = styled.div`
  text-align: center;

  & img {
    width: 100%;
  }

  & img:not(:last-child) {
    margin-right: 8px;
  }

  @media (max-width: 521px) {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const SponsorName = styled.div`
  margin-bottom: 15px;
  font-size: 18px;
`;

export const FooterWrapper = styled.div`
  margin-top: 40px;
  padding: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  color: ${({ theme }) => theme.colors.contrastText};
  background-color: ${({ theme }) => theme.colors.primary};

  @media (max-width: 558px) {
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

export const LogoLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.contrastText};

  & img {
    margin-top: 10px;
  }
`;

export const HorizontiWrapper = styled.div`
  text-align: center;
  line-height: 1.8em;

  & img {
    vertical-align: middle;
    margin-left: 5px;
  }
`;
