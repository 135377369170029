import { useState, useEffect } from 'react';

const getViewport = () => ({ width: window.innerWidth, height: window.innerHeight });

const useViewport = () => {
  const [viewport, setViewport] = useState(getViewport());

  useEffect(() => {
    const handleResize = () => setViewport(getViewport());
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return viewport;
};

export default useViewport;
