import { useEffect, useRef } from 'react';
import lottie, { AnimationConfigWithData, RendererType } from 'lottie-web';

interface LottieProps {
  animationData: any;
  width: number;
  height: number;
  config?: Omit<AnimationConfigWithData<RendererType>, 'container'>;
}

const Lottie = ({ animationData, width, height, config }: LottieProps) => {
  const element = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<any>();

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        animationData,
        ...config,
        container: element.current,
      });
    }
  }, [animationData, config]);

  return <div style={{ width, height }} ref={element}></div>;
};

export default Lottie;
