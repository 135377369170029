import styled from 'styled-components';
import { Link as _Link, Anchor as _Anchor } from '../Button';

export const SectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;

  & > * {
    flex-basis: auto;
    width: 100%;
    max-width: 500px;
  }

  @media (min-width: 830px) {
    min-height: 450px;
    max-height: 500px;
    margin-bottom: 0;
    height: auto;

    & > * {
      width: 47%;
      max-width: 500px;
    }
  }
`;

export const Paragraph = styled.p<{ $bold?: boolean }>`
  margin-bottom: 15px;
  white-space: pre-wrap;
  text-align: justify;
  color: ${({ theme }) => theme.colors.lighterText};
  font-weight: ${({ $bold = false }) => ($bold ? 'bold' : 'normal')};

  @media (max-width: 830px) {
    order: 3;
  }
`;

export const Title = styled.div<{ $color: string }>`
  position: relative;
  height: 50px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: bold;

  ::before {
    content: '';
    position: absolute;
    z-index: -1;
    width: 50px;
    height: 100%;
    border-radius: 50%;
    left: -20px;
    top: 0;
    background-color: ${({ $color }) => $color};
  }

  @media (max-width: 500px) {
    padding-left: 10px;
    ::before {
      left: -10px;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  max-width: 500px;
  flex: 1 0 450px;
  justify-content: center;
  align-items: center;

  & > img {
    width: 100%;
  }

  @media (max-width: 830px) {
    order: 2;
    flex: 1 0;

    & > img {
      max-width: 300px;
    }
  }

  @media (min-width: 831px) {
    & > img {
      max-width: 300px;
    }
  }
`;

export const LinkWrapper = styled.div`
  @media (max-width: 830px) {
    order: 4;
    text-align: center;
  }
`;

export const Link = styled(_Link)`
  max-width: 300px;
  width: 100%;
`;

export const Anchor = styled(_Anchor)`
  max-width: 300px;
  width: 100%;
`;
