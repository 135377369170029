import styled from 'styled-components';
import { BoxWrapper as _BoxWrapper } from '../styles';

export const BoxWrapper = styled(_BoxWrapper)`
  padding-top: 0;

  @media (max-width: 700px) {
    justify-content: center;
  }
`;

export const LottieWrapper = styled.div`
  margin: 0 auto;
`;

export const Details = styled.div`
  margin: 20px 0 30px;
  text-align: center;
`;
