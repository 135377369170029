import styled from 'styled-components';

export const PageWrapper = styled.div`
  padding: 0 70px;

  @media (min-aspect-ratio: 1 / 1) {
    margin-top: 40px;
  }

  @media (max-width: 500px) {
    padding: 0 15px;
  }
`;

export const PageTitle = styled.div`
  margin-bottom: 25px;
  font-size: 30px;
  line-height: 1.5;
  font-weight: bold;
  text-align: center;
`;

export const PageDescription = styled.div`
  font-size: 16px;
  font-weight: normal;
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style-type: none;
  margin: 100px 0 0;
  padding: 0;

  & > *:not(:last-child) {
    margin-bottom: 10vh;
  }

  @media (max-width: 1259px) {
    & > *:not(:last-child) {
      margin-bottom: 15vh;
    }
  }
`;
