import styled from 'styled-components';
import Section from '../../components/Section';

export const PageSection = styled.div`
  &:not(:last-child) {
    margin-bottom: 80px;
  }
`;

export const PageSectionTitle = styled.div`
  font-weight: bold;
  font-size: 25px;
  margin: 20px 0 40px;
`;

export const SocialMediaRow = styled.div`
  display: flex;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  @media (max-width: 830px) {
    order: 5;
  }
`;

export const RestaurantImage = styled(Section.Image)`
  border-radius: 50%;
`;
