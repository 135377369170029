import React, { useEffect, useState } from 'react';
import { setAppElement } from 'react-modal';
import { useLocation } from 'react-router-dom';
import hamburguerMenuIcon from '../../../assets/images/hamburguer-menu.svg';
import useViewport from '../../../hooks/useViewport';
import { Logo } from '../Header';
import { BoxTitle, Button, ButtonWrapper, LogoWrapper, NavLinkOption, StyledModal } from './styles';

setAppElement('#root');

const MenuModal = () => {
  const { pathname } = useLocation();
  const viewport = useViewport();
  const [isOpen, setOpen] = useState(false);

  const openModal = () => setOpen(true);
  const dismiss = () => setOpen(false);

  useEffect(() => {
    dismiss();
  }, [pathname]);

  const hasAnimation = viewport.width < 500;

  return (
    <>
      <LogoWrapper to="/">
        <Logo />
      </LogoWrapper>
      <ButtonWrapper>
        <Button onClick={openModal}>
          <img src={hamburguerMenuIcon} alt="Menu de navegação" />
        </Button>
      </ButtonWrapper>
      <StyledModal closeTimeoutMS={hasAnimation ? 300 : 0} isOpen={isOpen} onRequestClose={dismiss}>
        <BoxTitle>Acessos</BoxTitle>
        <NavLinkOption to="festival">O Festival</NavLinkOption>
        <NavLinkOption to="programacao">Programação</NavLinkOption>
        <NavLinkOption to="restaurantes">Restaurantes</NavLinkOption>
        <NavLinkOption to="pratos">Pratos</NavLinkOption>
      </StyledModal>
    </>
  );
};

export default MenuModal;
