import React from 'react';
import { LoaderSectionWrapper, LoaderWrapper, TextWrapper } from './styles';

type LoaderProps = {
  className?: string;
};

type LoaderSectionProps = {
  width?: number | string | undefined;
  height?: number | string | undefined;
  text?: string;
};

const Loader = (props: LoaderProps) => <LoaderWrapper alt="Carregando..." {...props} />;

export const LoaderSection = ({ width, height, text }: LoaderSectionProps) => (
  <LoaderSectionWrapper style={{ width, height }}>
    <Loader />
    {text && <TextWrapper>{text}</TextWrapper>}
  </LoaderSectionWrapper>
);

export default Loader;
