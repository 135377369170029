import React from 'react';
import ReactModal from 'react-modal';

/**
 * React Modal to integrate with styled-components
 *
 * Usage:
 * const StyledModal = styled(Modal)`
 *   overlay styles
 *
 *   & .${Modal.CONTENT_CLASS_NAME} {
 *     modal styles
 *   }
 * `;
 */
const Modal = ({ className, ...props }: ReactModal.Props) => (
  <ReactModal overlayClassName={className} className={Modal.CONTENT_CLASS_NAME} {...props} />
);

Modal.CONTENT_CLASS_NAME = 'Modal__Content';

export default Modal;
