import styled from 'styled-components';
import Input from '../../../components/Input';
import { BoxWrapper as _BoxWrapper } from '../styles';

export const BoxWrapper = styled(_BoxWrapper)`
  @media (max-width: 700px) {
    justify-content: center;
  }
`;

export const RegisterInput = styled(Input)`
  max-width: 400px;
  width: 100%;
  margin: 30px auto 0;
`;

export const Error = styled.div`
  max-width: 400px;
  width: 100%;
  height: 56px;
  margin: 10px auto 25px;
  color: ${({ theme }) => theme.colors.danger};
`;

export const TOSCheckbox = styled.div`
  margin-top: 20px;
  & > * {
    padding-left: 10px;
  }
`;
