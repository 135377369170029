import React from 'react';
import { useQuery } from 'react-query';
import { getMeals } from '../../api/festivalAPI';
import { ContentCategory } from '../../api/types';
import { DishCard } from '../../components/Card';
import { LoaderSection } from '../../components/Loader';
import Section, { AsyncSection } from '../../components/Section';
import { useFestival } from '../../contexts/FestivalContext';
import { PageWrapper, List } from '../styles';

const Dishes = () => {
  const { festival } = useFestival();
  const { data, isLoading } = useQuery('/meals', getMeals);
  console.log(festival);

  if (isLoading) {
    return (
      <PageWrapper>
        <LoaderSection
          height="100%"
          text="Carregando pratos participantes..."
        />
      </PageWrapper>
    );
  }

  const dishes = data?.data || [];
  const restaurants = data?.included || [];

  const list = dishes.map((dish) => {
    const restaurantId = dish.relationships.restaurant.data.id;
    const restaurant = restaurants.find(({ id }) => id === restaurantId);

    return (
      <li key={dish.id}>
        <DishCard
          id={dish.id}
          image={dish.attributes.photo}
          name={dish.attributes.name}
          description={dish.attributes.description}
          howToOrder={dish.attributes.how_to_order}
          contestRules={
            festival?.data.attributes.contest_rules
              ? festival?.data.attributes.contest_rules
              : ''
          }
          contestStartDate={festival?.data.attributes.contest_start_date ? festival?.data.attributes.contest_start_date : ''}
          contestEndDate={festival?.data.attributes.contest_end_date ? festival?.data.attributes.contest_end_date : ''}
          restaurant={{ id: restaurant!.id, name: restaurant!.attributes.name }}
        />
      </li>
    );
  });

  return (
    <PageWrapper>
      <AsyncSection category={ContentCategory.meals}>
        {({ name, content, image }) => (
          <Section>
            <Section.Title color={festival!.data.attributes.second_color}>
              {name}
            </Section.Title>
            <Section.Paragraph>{content}</Section.Paragraph>
            {festival!.data.attributes.contest_rules && (
              <Section.Anchor href={festival!.data.attributes.contest_rules}>
                Download do regulamento
              </Section.Anchor>
            )}
            <Section.Paragraph bold>
              CONFIRA OS PRATOS PARTICIPANTES DESSA EDIÇÃO 👇
            </Section.Paragraph>
            <Section.Image src={image} alt="Foto pratos" />
          </Section>
        )}
      </AsyncSection>
      <List>{list}</List>
    </PageWrapper>
  );
};

export default Dishes;
