import React from 'react';
import { Routes, Route, useLocation, Location, useNavigate, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import { LoaderSection } from './components/Loader';
import ModalRoute from './pages/Modal';
import Dishes from './pages/Dishes';
import Festival from './pages/Festival';
import Home from './pages/Home';
import Restaurant from './pages/Restaurant';
import Restaurants from './pages/Restaurants';
import Schedule from './pages/Schedule';
import Poll from './pages/Modal/Poll';
import Register from './pages/Modal/Register';
import SuccessRegister from './pages/Modal/SuccessRegister';
import { useFestival } from './contexts/FestivalContext';

type BackgroundLocationState = { backgroundLocation: Location } | undefined;

const MainRoutes = () => {
  const { isLoading, festival } = useFestival();

  const location = useLocation();
  const state = location.state as BackgroundLocationState;

  if (isLoading || !festival) {
    return <LoaderSection height="100%" />;
  }

  return (
    <Routes location={state?.backgroundLocation || location}>
      <Route path="/" element={<Layout />}>
        <Route path="festival" element={<Festival />} />
        <Route path="pratos" element={<Dishes />} />
        <Route path="programacao" element={<Schedule />} />
        <Route path="restaurantes">
          <Route path=":restaurantId" element={<Restaurant />} />
          <Route index element={<Restaurants />} />
        </Route>
        <Route path="votacao/*" element={<Navigate to="/" replace />} />
        <Route index element={<Home />} />
      </Route>
    </Routes>
  );
};

const ModalRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const state = location.state as BackgroundLocationState;
  const isOpenModal = !!state?.backgroundLocation;

  const handleRequestClose = () => navigate(state!.backgroundLocation.pathname);

  return (
    <ModalRoute location={location} isOpen={isOpenModal} onRequestClose={handleRequestClose}>
      <Routes>
        <Route path="/votacao">
          <Route path="registro" element={<Register />} />
          <Route path="sucesso" element={<SuccessRegister />} />
          <Route index element={<Poll />} />
        </Route>
        <Route path="*" element={null} />
      </Routes>
    </ModalRoute>
  );
};

const RootRoutes = () => (
  <>
    <MainRoutes />
    <ModalRoutes />
  </>
);

export default RootRoutes;
