import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ButtonProps, ButtonPropsWithTheme } from '.';

const baseStyles = css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 6px;
  padding: 10px 7px;
  cursor: pointer;
  font-weight: bold;
  border: 1px solid
    ${({ $color, theme }: ButtonPropsWithTheme) => theme.colors[$color || 'primary']};

  color: ${({ $color, theme, $filled = false }: ButtonPropsWithTheme) =>
    $filled ? theme.colors.contrastText : theme.colors[$color || 'primary']};

  background-color: ${({ $color, theme, $filled = false }: ButtonPropsWithTheme) =>
    $filled ? theme.colors[$color || 'primary'] : theme.colors.contrastText};
`;

const linkStyles = css`
  ${baseStyles}
  text-decoration: none;
`;

export const ButtonWrapper = styled.button<ButtonProps>`
  ${baseStyles}
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  ${({ disabled, theme }) => (disabled ? `background-color: ${theme.colors.gray};` : '')}
  ${({ disabled, theme }) => (disabled ? `border-color: ${theme.colors.gray};` : '')}
  ${({ disabled, theme }) => (disabled ? `color: ${theme.colors.contrastText};` : '')}
`;

export const LinkWrapper = styled(Link)<ButtonProps>`
  ${linkStyles}
`;

export const AnchorWrapper = styled.a<ButtonProps>`
  ${linkStyles}
`;
