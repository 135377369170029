import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Modal from '../../Modal';

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(-100%);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(100%);
  }
`;

export const StyledModal = styled(Modal)`
  position: absolute;
  inset: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.5);

  & .${Modal.CONTENT_CLASS_NAME} {
    position: absolute;
    overflow: auto;
    inset: 50% 0 0 0;
    max-width: 500px;
    margin: 0 auto;
    background-color: #ffffff;
    outline: none;
    padding: 30px 20px;
    height: min-content;
    max-height: 60%;
    border-radius: 20px 20px 0px 0px;
    top: 100%;
    animation-timing-function: ease-out;
    animation-direction: normal;
    transform: translateY(${({ isOpen }) => (isOpen ? '-100%' : '100%')});
    animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.3s;
  }

  @media (min-width: 500px) {
    & .${Modal.CONTENT_CLASS_NAME} {
      top: 50%;
      border-radius: 20px;
      transform: translateY(-50%);
      animation: none;
    }
  }
`;

export const LogoWrapper = styled(NavLink)`
  display: block;
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const Button = styled.button`
  background-color: transparent;
  border: none;
`;

export const BoxTitle = styled.div`
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
`;

export const NavLinkOption = styled(NavLink)`
  display: block;
  margin: 0 auto;
  max-width: 300px;
  padding: 15px 20px;
  border-radius: 8px;
  font-size: 18px;
  text-decoration: none;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.lighterText};
  background-color: ${({ theme }) => theme.colors.lightGray};

  &.active {
    color: ${({ theme }) => theme.colors.secondary};
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
`;
