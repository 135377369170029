import React from 'react';
import { useQueries } from 'react-query';
import { useParams } from 'react-router-dom';
import Section from '../../components/Section';
import { LoaderSection } from '../../components/Loader';
import DefaultSwiper, { DishesSwiper } from '../../components/Swiper';
import { Facebook, IFood, Instagram, Whatsapp } from '../../components/SocialIcon';
import { getRestaurant, getRestaurants } from '../../api/festivalAPI';
import { PageWrapper } from '../styles';
import { PageSection, PageSectionTitle, RestaurantImage, SocialMediaRow } from './styles';
import { SwiperSlide } from 'swiper/react';
import { RestaurantCard } from '../../components/Card';
import { useFestival } from '../../contexts/FestivalContext';

const Restaurant = () => {
  const { restaurantId: id } = useParams();
  const { festival } = useFestival();

  const queries = useQueries([
    { queryKey: '/restaurants', queryFn: getRestaurants },
    { queryKey: `/restaurants/${id}`, queryFn: () => getRestaurant(id!) },
  ]);

  if (queries.some(({ isLoading, data }) => isLoading || !data)) {
    return (
      <PageWrapper>
        <LoaderSection height="100%" text="Carregando restaurante..." />
      </PageWrapper>
    );
  }

  const [restaurantsQuery, restaurantQuery] = queries;
  const restaurant = restaurantQuery.data!.data.attributes;
  const restaurants = restaurantsQuery.data?.data || [];

  const list = restaurants
    .filter((currentRestaurant) => currentRestaurant.id !== id)
    .map((currentRestaurant) => (
      <SwiperSlide key={currentRestaurant.id}>
        <RestaurantCard
          restaurant={{ ...currentRestaurant.attributes, id: currentRestaurant.id }}
        />
      </SwiperSlide>
    ));

  return (
    <PageWrapper>
      <Section>
        <Section.Title color={festival!.data.attributes.fourth_color}>
          {restaurant.name}
        </Section.Title>
        <Section.Paragraph bold>{restaurant.opening_hours}</Section.Paragraph>
        <Section.Paragraph>{restaurant.description}</Section.Paragraph>
        <Section.Paragraph>{restaurant.address}</Section.Paragraph>
        <SocialMediaRow>
          <a href={restaurant.facebook} target="_blank" rel="noreferrer">
            <Facebook />
          </a>
          <a href={restaurant.instagram} target="_blank" rel="noreferrer">
            <Instagram />
          </a>
          <a href={`https://api.whatsapp.com/send?phone=55${restaurant.whatsapp.replace(/[^\d]/g, "")}`} target="_blank" rel="noreferrer">
            <Whatsapp />
          </a>
          <a href={restaurant.ifood} target="_blank" rel="noreferrer">
            <IFood />
          </a>
        </SocialMediaRow>
        <RestaurantImage src={restaurant.photo} alt={`Imagem do restaurante ${restaurant.name}`} />
      </Section>
      <PageSection>
        <PageSectionTitle>Pratos participantes</PageSectionTitle>
        <DishesSwiper restaurantId={id} />
      </PageSection>
      <PageSectionTitle>Outros restaurantes</PageSectionTitle>
      <DefaultSwiper>{list}</DefaultSwiper>
    </PageWrapper>
  );
};

export default Restaurant;
