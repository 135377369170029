import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { getFestival } from '../api/festivalAPI';
import { Festival, Resource, Supporter } from '../api/types';

interface Context {
  isLoading: boolean;
  festival:
    | {
        data: Resource<Festival>;
        included: Resource<Supporter>[];
      }
    | undefined;
}

let domain = window.location.hostname;

if (process.env.NODE_ENV !== 'production') {
 domain = 'melodious-biscuit-caa3b4.netlify.app';
}

export const FestivalContext = createContext<Context | null>(null);

export const useFestival = () => {
  const context = useContext(FestivalContext);

  if (context === null) {
    throw new Error('useFestival hook must be within a FestivalProvider');
  }

  return context;
};

const FestivalProvider = ({ children }: PropsWithChildren<{}>) => {
  const { data, isLoading } = useQuery('/festival', () => getFestival(domain), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  });

  const context = useMemo(() => {
    return { festival: data, isLoading };
  }, [data, isLoading]);

  return <FestivalContext.Provider value={context}>{children}</FestivalContext.Provider>;
};

export default FestivalProvider;
