import React from 'react';
import { TextAlign } from '../../types';
import { StyledInput } from './styles';

type Props = React.ComponentProps<typeof StyledInput> & {
  textAlign: TextAlign;
};

const Input = (props: Props) => <StyledInput {...props} />;

export default Input;
