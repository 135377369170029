import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { VoteMeal } from '../../../api/festivalAPI';
import { Vote, MealVote } from '../../../api/types';
import { BoxTitle, ButtonsWrapper, FormWrapper, Submit } from '../styles';
import { BoxWrapper, Error, RegisterInput, TOSCheckbox } from './styles';
import { LoaderSection } from '../../../components/Loader';
import { isDocumentValid } from '../../../utils';

const messages = {
  valid: 'CPF inválido, verifique se foi digitado corretamente.',
  unique: 'Só é permitido um voto por pessoa em cada prato. Já foi registrado voto neste prato com esse documento.',
  has_to_accept_privacy_policy: 'Você deve aceitar a política de privacidade para continuar.',
};

const useCaptcha = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (executeRecaptcha) {
      setLoading(false);
    }
  }, [executeRecaptcha]);

  return { executeRecaptcha, isLoading };
};

const RegisterBox = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { executeRecaptcha, isLoading } = useCaptcha();

  const [document, setDocument] = useState('');
  const [error, _setError] = useState<string | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [tosCheckbox, setTosCheckbox] = useState(false);

  const isValid = document.length === 11;
  const { votes } = state as { votes: Vote[] };

  const handleDocumentChange = ({ value }: NumberFormatValues) => setDocument(value);
  const handleGoBack = () => navigate(-1);

  const setError = (error: string) => {
    _setError(error);
    setSubmitting(false);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);

    if (!isDocumentValid(document)) {
      setError(messages.valid);
      return;
    }

    const captcha = await executeRecaptcha!();
    try {
      let vote: MealVote = { document_number: document, votes, captcha }
      if (tosCheckbox) vote.privacy_policy_accepted = true;
      await VoteMeal(vote);
      setSubmitting(false);
      navigate('../sucesso', { state });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 422) {
        setError(messages.unique);
      } else if (axios.isAxiosError(error) && error.response?.status === 412) {
        setError(messages.has_to_accept_privacy_policy)
      }
    }
  };

  if (isLoading) {
    return <LoaderSection height="100%" text="Carregando dados..." />;
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <BoxWrapper>
        <BoxTitle>Para registrar sua avaliação, por favor insira seu CPF:</BoxTitle>
        <NumberFormat
          placeholder="Digite seu CPF"
          mask="_"
          format="###.###.###-##"
          textAlign="center"
          value={document}
          onValueChange={handleDocumentChange}
          customInput={RegisterInput}
        />
        <TOSCheckbox>
          <input type="checkbox" name="terms_and_conditions" id="agree" onChange={(e) => setTosCheckbox(e.target.checked)} />
          <label htmlFor="agree">Li e aceito a <a
          href="https://www.sebrae.com.br/Sebrae/Portal%20Sebrae/LGPD/Sebrae%20-%20Pol%C3%ADtica%20de%20Privacidade.pdf"
          target='_blank'
          rel="noopener noreferrer">
            Política de Privacidade do Sebrae
          </a> para os fins de participação no concurso deste evento.</label>
        </TOSCheckbox>
        <Error>{error}</Error>
        <ButtonsWrapper>
          <Submit type="button" onClick={handleGoBack}>
            Voltar
          </Submit>
          <Submit filled disabled={!isValid || isSubmitting || !tosCheckbox}>
            {isSubmitting ? 'Carregando...' : 'Enviar Avaliação'}
          </Submit>
        </ButtonsWrapper>
      </BoxWrapper>
    </FormWrapper>
  );
};

const Register = () => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY} language="pt-BR">
    <RegisterBox />
  </GoogleReCaptchaProvider>
);

export default Register;
