import React from 'react';
import { RestaurantCard } from '../../components/Card';
import Section, { AsyncSection } from '../../components/Section';
import { PageWrapper, List } from '../styles';
import { ContentCategory } from '../../api/types';
import { useQuery } from 'react-query';
import { getRestaurants } from '../../api/festivalAPI';
import { LoaderSection } from '../../components/Loader';
import { useFestival } from '../../contexts/FestivalContext';

const Restaurants = () => {
  const {festival} = useFestival();
  const { data, isLoading } = useQuery('/restaurants', getRestaurants);

  if (isLoading) {
    return (
      <PageWrapper>
        <LoaderSection height="100%" text="Carregando restaurantes participantes..." />
      </PageWrapper>
    );
  }

  const restaurants = data?.data || [];
  const list = restaurants.map((restaurant) => (
    <li key={restaurant.id}>
      <RestaurantCard restaurant={{ ...restaurant.attributes, id: restaurant.id }} />
    </li>
  ));

  return (
    <PageWrapper>
      <AsyncSection category={ContentCategory.restaurants}>
        {({ name, content, image }) => (
          <Section>
            <Section.Title color={festival!.data.attributes.fourth_color}>{name}</Section.Title>
            <Section.Paragraph>{content}</Section.Paragraph>
            <Section.Paragraph bold>CONFIRA OS RESTAURANTES DESSA EDIÇÃO 👇</Section.Paragraph>
            <Section.Image src={image} alt="Imagem da programação" />
          </Section>
        )}
      </AsyncSection>
      <List>{list}</List>
    </PageWrapper>
  );
};

export default Restaurants;
