import React from 'react';
import { useQuery } from 'react-query';
import { Location, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { getBanners } from '../../../api/festivalAPI';
import { useFestival } from '../../../contexts/FestivalContext';
import MediaQuery from '../../MediaQuery';
import MenuModal from '../MenuModal';
import {
  Banner,
  BannerLegend,
  BannerWrapper,
  HeaderWrapper,
  NavBar,
  NavBarLink,
  NavBarList,
} from './styles';

const paginationConfig = {
  el: '.__banner-pagination',
  clickable: true,
  lockClass: '__banner-pagination-lock',
};

export const Logo = () => {
  const { festival } = useFestival();

  return (
    <img
      src={festival!.data.attributes.logo}
      alt={festival!.data.attributes.name}
      style={{ height: '138px' }}
    />
  );
};

const routes = [
  { to: '/festival', label: 'O Festival' },
  { to: '/programacao', label: 'Programação' },
  { to: '/', label: <Logo /> },
  { to: '/restaurantes', label: 'Restaurantes' },
  { to: '/pratos', label: 'Os Pratos' },
];

const Header = () => {
  const { data } = useQuery('/banners', getBanners);
  const { festival } = useFestival();

  const location = useLocation();
  const locationState = location.state as
    | { backgroundLocation?: Location }
    | undefined;
  const { pathname } = locationState?.backgroundLocation || location;

  const isHome = pathname === '/';
  const banners = data?.data || [];

  const bannerSlides = banners.map((banner) => (
    <SwiperSlide key={banner.id}>
      <Banner alt={banner.attributes.name} src={banner.attributes.image} />
    </SwiperSlide>
  ));

  const links = routes.map((route) => (
    <li key={route.to}>
      <NavBarLink to={route.to}>{route.label}</NavBarLink>
    </li>
  ));

  return (
    <HeaderWrapper slide={isHome}>
      <MediaQuery breakpoint={799} mode="max">
        <MenuModal />
      </MediaQuery>
      <MediaQuery breakpoint={800}>
        <NavBar>
          <NavBarList>{links}</NavBarList>
        </NavBar>
      </MediaQuery>
      <BannerWrapper hide={!isHome}>
        <Swiper
          centeredSlides
          centeredSlidesBounds
          loop
          autoplay
          slidesPerView={1}
          modules={[Pagination, Autoplay]}
          pagination={paginationConfig}
        >
          {bannerSlides}
        </Swiper>
        <div className="__banner-pagination" />
        {festival?.data.attributes.contest_rules && (
          <BannerLegend>Vote em seu restaurante favorito</BannerLegend>
        )}
      </BannerWrapper>
    </HeaderWrapper>
  );
};

export default Header;
