export const override = <T extends unknown>(
  overrideItem: T,
  array: T[],
  compareFunction: (a: T, b: T) => boolean,
) => {
  const newArray = array.map((item) => {
    if (compareFunction(item, overrideItem)) {
      return overrideItem;
    }

    return item;
  });

  if (newArray.length === array.length) {
    return newArray;
  }

  return [...newArray, overrideItem];
};

export const isDocumentValid = (document: string) => {
  let sum = 0;
  let remainder = 0;

  if (document === '00000000000') {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(document.substring(i - 1, i)) * (11 - i);
  }

  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(document.substring(9, 10))) {
    return false;
  }

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(document.substring(i - 1, i)) * (12 - i);
  }

  remainder = (sum * 10) % 11;
  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }
  if (remainder !== parseInt(document.substring(10, 11))) {
    return false;
  }
  return true;
};
