import styled, { keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';

const slideIn = keyframes`
  from {
    transform: scaleY(0.32);
  }

  60%, 70% {
    transform: scaleY(1.1);
  }

  to {
    transform: scaleY(1);
  }
`;

const slideOut = keyframes`
  from {
    transform: scaleY(4);
  }

  60%, 70% {
    transform: scaleY(0.85);
  }

  to {
    transform: scaleY(1);
  }

  @media (min-height: 680px) {

  }
`;

export const HeaderWrapper = styled.div<{ slide: boolean }>`
  position: relative;
  z-index: 99998;
  padding: 40px 0 0;
  margin-bottom: 60px;
  color: ${({ theme }) => theme.colors.contrastText};
  overflow: ${({ slide }) => (slide ? 'initial' : 'hidden')};
  height: ${({ slide }) => (slide ? 'auto' : '240px')};

  ::before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: -20%;
    right: -20%;
    bottom: ${({ slide }) => (slide ? '-60px' : '0')};
    background-color: ${({ theme }) => theme.colors.primary};
    border-bottom-right-radius: 100% ${({ slide }) => (slide ? '80%' : '120%')};
    border-bottom-left-radius: 100% ${({ slide }) => (slide ? '80%' : '120%')};

    @media (min-aspect-ratio: 1 / 1) {
      bottom: 0;
      transform-origin: top;
      animation: ${({ slide }) => (slide ? slideIn : slideOut)} 0.7s;
      border-bottom-right-radius: 100% ${({ slide }) => (slide ? '80%' : '200%')};
      border-bottom-left-radius: 100% ${({ slide }) => (slide ? '80%' : '200%')};
    }
  }

  @media (min-aspect-ratio: 1 / 1) {
    overflow: initial;
    margin-bottom: 0;
    height: ${({ slide }) => (slide ? 'calc(100% + 20px)' : '40%')};
  }
`;

export const NavBar = styled.nav`
  width: 90%;
  max-width: 960px;
  margin: 0 auto;
`;

export const NavBarList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  font-weight: bold;
`;

export const NavBarLink = styled(NavLink)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.contrastText};

  &.active {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const BannerWrapper = styled.div<{ hide: boolean }>`
  margin-top: 15px;
  height: 100%;
  text-align: center;
  font-weight: bold;
  opacity: ${({ hide }) => (hide ? '0' : '1')};

  & .swiper {
    height: min-content;
    margin-bottom: 10px;
  }

  @media (min-aspect-ratio: 1 / 1) {
    height: 53vh;
    transform: ${({ hide }) => (hide ? 'scale(1.1)' : 'scale(1)')};
    transition: opacity 0.4s, transform ${({ hide }) => (hide ? '1s' : '0s')};

    & .swiper {
      height: 100%;
    }
  }
`;

export const Banner = styled.img`
  @media (min-aspect-ratio: 1 / 1) {
    height: 35vw;
    max-height: 100%;
  }
  @media (max-aspect-ratio: 1 / 1) {
    width: 90%;
    max-width: 950px;
  }
`;

export const BannerLegend = styled.p`
  margin-top: 15px;
  font-size: 18px;
`;
