import styled, { css, FlattenSimpleInterpolation, keyframes } from 'styled-components';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { Props } from '.';

export enum PollPaths {
  poll = '/votacao',
  register = '/votacao/registro',
  success = '/votacao/sucesso',
}

const scaleIn = keyframes`
  from {
    transform: scale(1.1) translateY(-50%);
  }

  to {
    transform: scale(1) translateY(-50%);
  }
`;

const scaleOut = keyframes`
  from {
    transform: scale(1) translateY(-50%);
  }

  to {
    transform: scale(1.1) translateY(-50%);
  }
`;

const baseStyles = css`
  position: absolute;
  inset: 0;
  padding-top: 10px;
  background-color: #ffffff;
  outline: none;
  height: 100%;
`;

const poll = css`
  ${baseStyles}

  @media (min-width: 800px) {
    padding-top: 0;
    margin: 0 auto;
    width: 700px;
    height: 85%;
    max-height: 780px;
    inset: 50% 0 0 0;
    transform: translateY(-50%);
    border-radius: 8px;
  }
`;

const register = css`
  ${baseStyles}

  @media (min-width: 700px) {
    margin: 0 auto;
    width: 700px;
    height: 85%;
    max-height: 350px;
    inset: 50% 0 0 0;
    transform: translateY(-50%);
    border-radius: 8px;
  }
`;

const success = css`
  ${baseStyles}
  padding-top: 0;
  margin: 0 auto;

  @media (min-width: 500px) {
    width: 500px;
    height: 450px;
    inset: 50% 0 0 0;
    transform: translateY(-50%);
    border-radius: 8px;
  }
`;

const contentStyles: { [Route in PollPaths]: FlattenSimpleInterpolation } = {
  [PollPaths.poll]: poll,
  [PollPaths.register]: register,
  [PollPaths.success]: success,
};

export const StyledModal = styled(Modal)<Props>`
  position: absolute;
  inset: 0;
  height: 100vh;
  z-index: 999999;
  background-color: #ffffff;

  &.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  &.ReactModal__Overlay--after-open {
    opacity: 1;
  }

  &.ReactModal__Overlay--before-close {
    opacity: 0;
  }

  & .${Modal.CONTENT_CLASS_NAME} {
    ${({ location }) => contentStyles[location.pathname as PollPaths] || baseStyles}
  }

  @media (min-width: 800px) {
    height: auto;
    background-color: rgba(0, 0, 0, 0.5);

    & .${Modal.CONTENT_CLASS_NAME} {
      transform-origin: top;
      animation: ${scaleIn} 300ms;
    }

    & .ReactModal__Content--before-close {
      transform-origin: top;
      animation: ${scaleOut} 300ms;
    }
  }
`;

export const FormWrapper = styled.form`
  height: 100%;
`;

export const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px 20px 0;

  @media (min-width: 800px) {
    padding: 30px 30px 0;
  }
`;

export const BoxTitle = styled.div`
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
`;

export const ButtonsWrapper = styled.div`
  padding: 15px 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const Submit = styled(Button)`
  width: 47%;
`;
