import styled from 'styled-components';
import { TextAlign } from '../../types';

export const StyledInput = styled.input<{ textAlign?: TextAlign; }>`
  font-family: 'Roboto';
  padding: 10px;
  border: none;
  border-radius: 8px;
  text-align: ${({ textAlign = 'start' }) => textAlign};
  background-color: ${({ theme }) => theme.colors.blue};
`;
