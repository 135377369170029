import axios from 'axios';
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as festivalAPI from '../../api/festivalAPI';
import { Box, BoxTitle, Modal } from './styles';

interface Context {
  isOpen: boolean;
  message: string | null;
  open: (errorMessage: string) => void;
  dismiss: () => void;
}

export const ErrorContext = createContext<Context | null>(null);

export const useError = () => {
  const context = useContext(ErrorContext);

  if (context === null) {
    throw new Error('useError hook must be within a ErrorProvider');
  }

  return context;
};

export const ErrorProvider = ({ children }: PropsWithChildren<{}>) => {
  const isMount = useRef<boolean>(false);
  const [isOpen, setOpen] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  const context = useMemo(() => {
    const open = (errorMessage: string) => {
      setOpen(true);
      setMessage(errorMessage);
    };

    const dismiss = () => {
      setOpen(false);
      setMessage(null);
    };

    return { open, dismiss, isOpen, message };
  }, [isOpen, message]);

  useEffect(() => {
    if (isMount.current) {
      return;
    }

    isMount.current = true;
    const ids = festivalAPI.use(
      'response',
      (config) => config,
      (error) => {
        if (axios.isAxiosError(error)) {
          if (!error.response) {
            context.open(
              'Não foi possivel se conectar ao servidor, verifique sua conexão com a internet.',
            );
          } else if (error.response.status >= 500) {
            context.open('Por favor, entre em contato caso o erro persista.');
          } else {
            throw error;
          }
        }
      },
    );

    return () => festivalAPI.eject('response', ids);
  }, [context]);

  return <ErrorContext.Provider value={context}>{children}</ErrorContext.Provider>;
};

const ErrorModal = () => {
  const { isOpen, dismiss, message } = useError();

  return (
    <Modal isOpen={isOpen} onRequestClose={dismiss}>
      <BoxTitle>Ops! Ocorreu um erro...</BoxTitle>
      <Box>{message}</Box>
    </Modal>
  );
};

export default ErrorModal;
