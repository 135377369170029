import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Anchor, Link as ButtonLink } from '../Button';
import { TextAlign } from '../../types';

export const CardWrapper = styled.div`
  width: 100%;
  max-width: 280px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const Image = styled.img`
  border-radius: 8px;
  margin: 0 auto 10px;
  object-fit: cover;
  width: 90%;
  height: 220px;
`;

export const Name = styled.div`
  margin: 0 10px 15px;
  padding: 3px 5px;
  border-radius: 8% / 100%;
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.contrastText};
  background-color: ${({ theme }) => theme.colors.secondary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
`;

export const NameLink = styled(Link)`
  color: ${({ theme }) => theme.colors.contrastText};
  text-decoration: none;
`;

export const Paragraph = styled.div`
  padding: 0 10px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 21px;
  text-align: center;
  padding: 20px 0;
`;

export const Description = styled.span`
  font-weight: 500;
  font-size: 15px;
`;

export const Bottom = styled.div`
  padding: 0 10px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const Details = styled.div<{ $textAlign?: TextAlign; $bold?: boolean }>`
  font-weight: lighter;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.lighterText};
  text-align: ${({ $textAlign = 'start' }) => $textAlign};
  font-weight: ${({ $bold = false }) => ($bold ? 'bold' : 'normal')};

  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const DishCardWrapper = styled(CardWrapper)`
  height: 100%;
`;

export const DishButton = styled(ButtonLink)`
  width: 47%;
`;

export const DishAnchor = styled(Anchor)`
  width: 47%;
`;

export const CardLink = styled(Link)`
  display: block;
  text-decoration: none;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
`;

export const SocialMediaRow = styled(Bottom)`
  justify-content: center;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;
