import { useQuery } from 'react-query';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react';
import { getMeals, getMealsByRestaurant } from '../../api/festivalAPI';
import { useFestival } from '../../contexts/FestivalContext';
import { DishCard } from '../Card';
import { LoaderSection } from '../Loader';

type DishesSwiperProps = {
  restaurantId?: string;
};

const DefaultSwiper = (props: SwiperProps) => (
  <Swiper
    navigation
    centeredSlides
    centeredSlidesBounds
    preventClicks={false}
    modules={[Navigation]}
    breakpoints={{
      700: { slidesPerView: 2, centeredSlides: false },
      1000: { slidesPerView: 3, centeredSlides: false },
      1300: { slidesPerView: 4, centeredSlides: false },
    }}
    {...props}
  />
);

export const DishesSwiper = ({ restaurantId }: DishesSwiperProps) => {
  const { festival } = useFestival();

  const key = restaurantId ? `/restaurants/${restaurantId}/meals` : '/meals';
  const fetcher = restaurantId
    ? () => getMealsByRestaurant(restaurantId)
    : getMeals;
  const { data, isLoading } = useQuery(key, fetcher);

  if (isLoading) {
    <LoaderSection height="100%" text="Carregando pratos participantes..." />;
  }

  const dishes = data?.data || [];
  const restaurants = data?.included || [];

  const list = dishes.map((dish) => {
    const restaurantId = dish.relationships.restaurant.data.id;
    const restaurant = restaurants.find(({ id }) => id === restaurantId);

    return (
      <SwiperSlide key={dish.id}>
        <DishCard
          id={dish.id}
          image={dish.attributes.photo}
          name={dish.attributes.name}
          description={dish.attributes.description}
          howToOrder={dish.attributes.how_to_order}
          contestRules={
            festival?.data.attributes.contest_rules
              ? festival?.data.attributes.contest_rules
              : ''
          }
          contestStartDate={festival?.data.attributes.contest_start_date? festival?.data.attributes.contest_start_date : ''}
          contestEndDate={festival?.data.attributes.contest_end_date? festival?.data.attributes.contest_end_date : ''}
          restaurant={{ id: restaurant!.id, name: restaurant!.attributes.name }}
        />
      </SwiperSlide>
    );
  });

  return (
    <div>
      <DefaultSwiper>{list}</DefaultSwiper>
    </div>
  );
};

export default DefaultSwiper;
