import React from 'react';
import { useQuery } from 'react-query';
import Section, { AsyncSection } from '../../components/Section';
import Card from '../../components/Card';
import { ContentCategory } from '../../api/types';
import { getAttractions } from '../../api/festivalAPI';
import { LoaderSection } from '../../components/Loader';
import { PageWrapper } from '../styles';
import { CardWrapper, GroupImage, List } from './styles';
import { useFestival } from '../../contexts/FestivalContext';

const formatPrice = (price: string | null) => {
  if (price === null) {
    return price;
  }

  const CONFIG = { style: 'currency', currency: 'BRL' };
  return new Intl.NumberFormat('pt-BR', CONFIG).format(parseFloat(price));
};

const formatDate = (date: string) => {
  const options = { month: 'long', day: 'numeric' } as const;

  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(`${date}T00:00:00`));
};

const Schedule = () => {
  const { festival } = useFestival();
  const { data, isLoading } = useQuery('/attractions', getAttractions);

  if (isLoading) {
    return (
      <PageWrapper>
        <LoaderSection height="100%" text="Carregando programação..." />
      </PageWrapper>
    );
  }

  const attractions = data?.data || [];
  const list = attractions.map((attraction) => (
    <li key={attraction.id}>
      <CardWrapper>
        <GroupImage src={attraction.attributes.photo} />
        <Card.Title>{attraction.attributes.name}</Card.Title>
        <Card.Paragraph>
          <Card.Details bold textAlign="center">
            {formatDate(attraction.attributes.event_date)}
          </Card.Details>
          <Card.Details textAlign="center">{attraction.attributes.event_time}</Card.Details>
          <Card.Details textAlign="center">{attraction.attributes.location}</Card.Details>
          <Card.Details textAlign="center">{attraction.attributes.address}</Card.Details>
        </Card.Paragraph>
        <Card.Paragraph>
          <Card.Details bold textAlign="center">
            ENTRADA {formatPrice(attraction.attributes.price) || 'GRATUITA'}
          </Card.Details>
        </Card.Paragraph>
      </CardWrapper>
    </li>
  ));

  return (
    <PageWrapper>
      <AsyncSection category={ContentCategory.attraction}>
        {({ name, image, content }) => (
          <Section>
            <Section.Title color={festival!.data.attributes.third_color}>{name}</Section.Title>
            <Section.Paragraph>{content}</Section.Paragraph>
            <Section.Paragraph bold>CONFIRA AS ATRAÇÕES DESSA EDIÇÃO 👇</Section.Paragraph>
            <Section.Image src={image} />
          </Section>
        )}
      </AsyncSection>
      <List>{list}</List>
    </PageWrapper>
  );
};

export default Schedule;
