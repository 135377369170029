import React, { useEffect, useState } from 'react';
import ReactModal, { setAppElement } from 'react-modal';
import { Location } from 'react-router-dom';
import { StyledModal } from './styles';

setAppElement('#root');

export type Props = ReactModal.Props & {
  location: Location;
};

const ModalRoute = ({ location, ...props }: Props) => {
  const [currentLocation, setLocation] = useState(location);

  useEffect(() => {
    const state = location.state as { backgroundLocation: Location } | undefined;
    if (state?.backgroundLocation) {
      setLocation(location);
    }
  }, [location]);

  return <StyledModal closeTimeoutMS={300} location={currentLocation} {...props} />;
};

export default ModalRoute;
