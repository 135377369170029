import React from 'react';
import { Location, useLocation } from 'react-router-dom';
import successAnimation from '../../../assets/lotties/success-animation.json';
import { Link } from '../../../components/Button';
import Lottie from '../../../components/Lottie';
import { BoxTitle } from '../styles';
import { BoxWrapper, Details, LottieWrapper } from './styles';

const config = {
  loop: false,
};

const SuccessRegister = () => {
  const location = useLocation();
  const { backgroundLocation } = location.state as { backgroundLocation: Location };

  return (
    <BoxWrapper>
      <LottieWrapper>
        <Lottie config={config} animationData={successAnimation} width={250} height={250} />
      </LottieWrapper>
      <BoxTitle>Agradecemos por sua avaliação!</BoxTitle>
      <Details>
        Fique atento aos nosso canais oficiais e redes sociais para a divulgação do resultado!
      </Details>
      <Link to={backgroundLocation}>Voltar para o início</Link>
    </BoxWrapper>
  );
};

export default SuccessRegister;
