import React from 'react';
import { PropsWithChildren } from 'react';
import useViewport from '../../hooks/useViewport';

type Props = {
  mode?: 'min' | 'max';
  breakpoint: number;
};

const MediaQuery = ({ mode = 'min', breakpoint, children }: PropsWithChildren<Props>) => {
  const viewport = useViewport();
  const handleMode = (value: boolean) => (mode === 'min' ? value : !value);

  return handleMode(viewport.width > breakpoint) ? <>{children}</> : null;
};

export default MediaQuery;
