import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import { Location, Outlet, useLocation } from 'react-router-dom';
import { useFestival } from '../../contexts/FestivalContext';
import Footer from './Footer';
import Header from './Header';
import { LayoutWrapper, Body } from './styles';

const useZIndex = (isHome: boolean) => {
  const [zIndex, setZIndex] = useState(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isHome) {
      setZIndex(0);
      timeoutRef.current && clearTimeout(timeoutRef.current);
    } else {
      timeoutRef.current = setTimeout(() => setZIndex(99999), 500);
    }
  }, [isHome]);

  return zIndex;
};

const Layout = () => {
  const { festival } = useFestival();
  const layoutRef = useRef<HTMLDivElement | null>(null);
  const backgroundLocationRef = useRef<Location | null>(null);

  const location = useLocation();
  const locationState = location.state as { backgroundLocation?: Location } | undefined;
  const { pathname } = locationState?.backgroundLocation || location;

  const isHome = pathname === '/';
  const zIndex = useZIndex(isHome);

  useEffect(() => {
    if (locationState?.backgroundLocation && !backgroundLocationRef.current) {
      backgroundLocationRef.current = locationState.backgroundLocation;
    }
  }, [locationState]);

  useEffect(() => {
    if (backgroundLocationRef.current) {
      backgroundLocationRef.current = null;
    } else {
      layoutRef.current!.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <LayoutWrapper ref={layoutRef}>
      <Helmet>
        <title>{festival!.data.attributes.name}</title>
      </Helmet>
      <Header />
      <TransitionGroup>
        <CSSTransition key={pathname} classNames="fade" timeout={300}>
          <Body slide={isHome} zIndex={zIndex}>
            <Outlet />
          </Body>
        </CSSTransition>
      </TransitionGroup>
      <Footer />
    </LayoutWrapper>
  );
};

export default Layout;
