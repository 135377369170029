import React from 'react';
import Section, { AsyncSection } from '../../components/Section';
import { useFestival } from '../../contexts/FestivalContext';
import { ContentCategory } from '../../api/types';
import { PageWrapper } from '../styles';

const Festival = () => {
  const { festival } = useFestival();

  return (
    <PageWrapper>
      <AsyncSection category={ContentCategory.about}>
        {({ name, content, image }) => (
          <Section>
            <Section.Title color={festival!.data.attributes.first_color}>{name}</Section.Title>
            <Section.Paragraph>{content}</Section.Paragraph>
            <Section.Paragraph bold>
              TEMA DO ANO: {festival!.data.attributes.year_theme}
            </Section.Paragraph>
            <Section.Image src={image} alt="Imagem do festival" />
          </Section>
        )}
      </AsyncSection>
      <AsyncSection category={ContentCategory.chef}>
        {({ name, content, image }) => (
          <Section>
            <Section.Image src={image} alt="Imagem do chefe" />
            <Section.Title color={festival!.data.attributes.second_color}>{name}</Section.Title>
            <Section.Paragraph>{content}</Section.Paragraph>
          </Section>
        )}
      </AsyncSection>
    </PageWrapper>
  );
};

export default Festival;
