import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#1D080A',
    secondary: '#F26836',
    danger: '#FF0000',
    blue: '#DAE9EF',
    gray: '#CBCBCB',
    lightGray: '#EEEEEE',
    text: '#000000',
    contrastText: '#ffffff',
    lighterText: '#2C2D32',
  },
};

export default theme;
