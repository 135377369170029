import styled from 'styled-components';
import loaderIcon from '../../assets/images/loader.svg';

export const LoaderSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoaderWrapper = styled.img.attrs({
  src: loaderIcon,
})`
  width: 100px;
`;

export const TextWrapper = styled.div`
  margin-top: 10px;
`;
