import styled from 'styled-components';
import _Card from '../../components/Card';
import { Link } from '../../components/Button';
import { PageWrapper as _PageWrapper } from '../styles';

export const PageWrapper = styled(_PageWrapper)`
  margin-top: 100px;
`;

export const Card = styled(_Card)`
  @media (max-width: 700px) {
    margin: 0 auto;
  }
`;

export const MoreDishes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 80px;
`;

export const MoreDishesLink = styled(Link)`
  width: 340px;
  margin: 0 10px;
`;

export const Divider = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary};
  height: 2px;
`;
