import React from 'react';
import facebookIcon from '../../assets/images/facebook.png';
import instagramIcon from '../../assets/images/instagram.png';
import whatsappIcon from '../../assets/images/whatsapp.png';
import ifoodIcon from '../../assets/images/ifood.png';
import { SocialIconImage } from './styles';

export const Facebook = () => <SocialIconImage src={facebookIcon} alt="Rede social Facebook" />;
export const Instagram = () => <SocialIconImage src={instagramIcon} alt="Rede social Instagram" />;
export const Whatsapp = () => <SocialIconImage src={whatsappIcon} alt="Rede social Whatsapp" />;
export const IFood = () => <SocialIconImage src={ifoodIcon} alt="Rede social IFood" />;
