import React, { useMemo } from 'react';
import logoFooter from '../../../assets/images/logo-footer.png';
import logoHorizonti from '../../../assets/images/logo-horizonti.png';
import logoSebrae from '../../../assets/images/logo-sebrae.png';
import { Resource, Supporter, SupporterCategory } from '../../../api/types';
import { useFestival } from '../../../contexts/FestivalContext';
import {
  FooterWrapper,
  SponsorsWrapper,
  SponsorSection,
  SponsorName,
  HorizontiWrapper,
  LogoLink,
} from './styles';

const LANDPAGE_URL = 'https://oferta.sebraemg.com.br/prepara-gastronomia';

const forceAbsoluteLink = (value: string) => {
  const ABSOLUTE_PREFIX = 'https://';
  if (value.startsWith(ABSOLUTE_PREFIX)) {
    return value;
  }

  return `${ABSOLUTE_PREFIX}/${value}`;
};

const renderSupporter = (support: Resource<Supporter>) => (
  <a
    key={support.id}
    href={forceAbsoluteLink(support.attributes.link)}
    target="_blank"
    rel="noreferrer"
  >
    <img
      src={support.attributes.logo}
      alt={support.attributes.name}
      style={{ width: '147px' }}
    />
  </a>
);

const renderSupporters = (supporters: Resource<Supporter>[]) =>
  supporters.reduce<JSX.Element[][]>(
    (acc, value) => {
      const [achievements, sponsors, support] = acc;

      switch (value.attributes.category) {
        case SupporterCategory.Achievement:
          return [[...achievements, renderSupporter(value)], sponsors, support];
        case SupporterCategory.Sponsors:
          return [achievements, [...sponsors, renderSupporter(value)], support];
        default:
          return [achievements, sponsors, [...support, renderSupporter(value)]];
      }
    },
    [[], [], []]
  );

const Footer = () => {
  const { festival } = useFestival();
  const [achievements, sponsors, support] = useMemo(
    () => renderSupporters(festival?.included || []),
    [festival]
  );

  return (
    <>
      <SponsorsWrapper>
        {achievements.length && (
          <SponsorSection>
            <SponsorName>Realização</SponsorName>
            {achievements}
          </SponsorSection>
        )}
        {sponsors.length > 0 && (
          <SponsorSection>
            <SponsorName>Patrocínio</SponsorName>
            {sponsors}
          </SponsorSection>
        )}
        {support.length > 0 && (
          <SponsorSection>
            <SponsorName>Apoio</SponsorName>
            {support}
          </SponsorSection>
        )}
      </SponsorsWrapper>
      <FooterWrapper>
        <LogoLink href={LANDPAGE_URL} target="_blank" rel="noreferrer">
          Clique e conheça:
          <img src={logoFooter} alt="Prepara Gastronomia Rodapé" />
        </LogoLink>
        <HorizontiWrapper>
          <div>{festival?.data.attributes.name}</div>
          <div>
            desenvolvido por
            <img src={logoHorizonti} alt="Horizonti Rodapé" />
          </div>
        </HorizontiWrapper>
        <img src={logoSebrae} alt="Horizonti Rodapé" />
      </FooterWrapper>
    </>
  );
};

export default Footer;
