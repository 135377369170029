import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQuestions } from '../../../api/festivalAPI';
import { Meal, Question, Resource, Vote } from '../../../api/types';
import Card from '../../../components/Card';
import { LoaderSection } from '../../../components/Loader';
import { override } from '../../../utils';
import { BoxWrapper, BoxTitle, Submit, FormWrapper } from '../styles';
import Questions from './Questions';
import { CardWrapper, SectionsWrapper, ButtonsWrapper } from './styles';

export interface VoteState extends Vote {
  description: string;
}

const questionToVoteState = (dishId: string) => {
  return ({ id, attributes: { description } }: Resource<Question>): VoteState => ({
    grade: -1,
    meal_id: dishId,
    question_id: id,
    description,
  });
};

const voteStateToVote = ({ description: _, ...vote }: VoteState): Vote => vote;

const Poll = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [votes, setVotes] = useState<VoteState[]>([]);
  const [isLoading, setLoading] = useState(true);

  const { dish, backgroundLocation } = location.state as {
    backgroundLocation: Location;
    dish: Meal & { id: string };
  };

  useEffect(() => {
    (async function getQuestionsOnMount() {
      const { data } = await getQuestions();
      setVotes(data.map(questionToVoteState(dish.id)));
      setLoading(false);
    })();
  }, [dish.id]);

  const isValid = votes.every((vote) => vote.grade !== -1);

  const handleVotesOnChange = (grade: number, vote: VoteState) => {
    const newVote = { ...vote, grade };
    setVotes((state) => override(newVote, state, (a, b) => a.question_id === b.question_id));
  };

  const handleGoBack = () => navigate(-1);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const state = { backgroundLocation, votes: votes.map(voteStateToVote) };
    navigate('registro', { state });
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <BoxWrapper>
        <BoxTitle>Você está avaliando:</BoxTitle>
        <CardWrapper>
          <Card.Name>{dish.name}</Card.Name>
          <Card.Paragraph>
            <Card.Description>{dish.description}</Card.Description>
          </Card.Paragraph>
        </CardWrapper>
        <SectionsWrapper>
          {isLoading && <LoaderSection text="Carregando perguntas..." />}
          <Questions votes={votes} onChange={handleVotesOnChange} />
        </SectionsWrapper>
        <ButtonsWrapper>
          <Submit type="button" onClick={handleGoBack}>
            Voltar
          </Submit>
          <Submit filled disabled={!isValid || isLoading}>
            Avaliar
          </Submit>
        </ButtonsWrapper>
      </BoxWrapper>
    </FormWrapper>
  );
};

export default Poll;
