import React from 'react';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { Restaurant } from '../../api/types';
import { TextAlign } from '../../types';
import { Facebook, IFood, Instagram, Whatsapp } from '../SocialIcon';
import {
  CardWrapper,
  CardLink,
  Description,
  Details,
  DishButton,
  Image,
  Name,
  Paragraph,
  Title,
  DishCardWrapper,
  DishAnchor,
  Bottom,
  SocialMediaRow,
  NameLink,
} from './styles';

type DetailsProps = {
  textAlign?: TextAlign;
  bold?: boolean;
};

type DishProps = {
  id: string;
  image: string;
  name: string;
  description: string;
  howToOrder: string;
  contestRules: string;
  contestStartDate: string;
  contestEndDate: string;
  restaurant: { id: string; name: string };
  className?: string;
};

type RestaurantProps = {
  restaurant: Restaurant & { id: string };
};

const Card = (props: React.ComponentProps<typeof CardWrapper>) => (
  <CardWrapper {...props} />
);

Card.Details = ({
  textAlign,
  bold,
  ...props
}: React.ComponentProps<typeof Details> & DetailsProps) => (
  <Details {...props} $textAlign={textAlign} $bold={bold} />
);

Card.Image = Image;
Card.Name = Name;
Card.Paragraph = Paragraph;
Card.Title = Title;
Card.Description = Description;
Card.Bottom = Bottom;

export const DishCard = (props: DishProps) => {
  const backgroundLocation = useLocation();

  const {
    id,
    image,
    name,
    description,
    howToOrder,
    restaurant,
    className,
    contestRules,
    contestStartDate,
    contestEndDate,
  } = props;
  const dish = { id, image, name, description };

  let timestampStart, timestampEnd;

  if (contestEndDate && contestStartDate) {
    timestampStart = new Date(contestStartDate).getTime();

    timestampEnd = new Date(contestEndDate).getTime();
  }

  const timeNow = Date.now();

  return (
    <DishCardWrapper className={className}>
      <Card.Image
        src={image}
        alt={`Image do prato de ${name} descrito abaixo`}
      />
      <Card.Name>
        <NameLink to={`/restaurantes/${restaurant.id}`}>
          {restaurant.name}
        </NameLink>
      </Card.Name>
      <Card.Paragraph>
        <Card.Description>{name}</Card.Description>
      </Card.Paragraph>
      <Card.Paragraph>
        <Card.Details>{description}</Card.Details>
      </Card.Paragraph>
      <Bottom>
        {contestRules && timestampStart && timestampEnd && timeNow > timestampStart && timeNow < timestampEnd &&  (
          <DishButton filled to="/votacao" state={{ backgroundLocation, dish }}>
            Votar
          </DishButton>
        )}
        {howToOrder && (
          <DishAnchor href={howToOrder} target="_blank" rel="noreferrer">
            Como pedir?
          </DishAnchor>
        )}
      </Bottom>
    </DishCardWrapper>
  );
};

export const RestaurantCard = ({ restaurant }: RestaurantProps) => {
  const {
    id,
    photo,
    name,
    address,
    phone,
    description,
    facebook,
    instagram,
    whatsapp,
    ifood,
    opening_hours: openingHours,
  } = restaurant;

  return (
    <Card>
      <CardLink to={`/restaurantes/${id}`}>
        <Card.Image src={photo} />
        <Card.Title>{name}</Card.Title>
      </CardLink>
      <Card.Paragraph>
        <Card.Details bold textAlign="center">
          {openingHours}
        </Card.Details>
        <Card.Details textAlign="center">{phone}</Card.Details>
      </Card.Paragraph>
      <Card.Paragraph>
        <Card.Details textAlign="center">{parse(description)}</Card.Details>
      </Card.Paragraph>
      <Card.Paragraph>
        <Card.Details textAlign="center">{address}</Card.Details>
      </Card.Paragraph>
      <SocialMediaRow>
        {facebook && (
          <a href={facebook} target="_blank" rel="noreferrer">
            <Facebook />
          </a>
        )}
        {instagram && (
          <a href={instagram} target="_blank" rel="noreferrer">
            <Instagram />
          </a>
        )}
        {whatsapp && (
          <a href={`https://api.whatsapp.com/send?phone=55${whatsapp.replace(/[^\d]/g, "")}`} target="_blank" rel="noreferrer">
            <Whatsapp />
          </a>
        )}
        {ifood && (
          <a href={ifood} target="_blank" rel="noreferrer">
            <IFood />
          </a>
        )}
      </SocialMediaRow>
    </Card>
  );
};

export default Card;
