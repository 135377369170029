import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import {
  Attraction,
  Banner,
  Content,
  ContentCategory,
  Festival,
  Meal,
  MealVote,
  Question,
  ResourceDetails,
  ResourceList,
  Restaurant,
  Supporter,
} from './types';

const baseURL = `${process.env.REACT_APP_FESTIVAL_BASE_URL}/web`;

const api = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const apiWithFestivalRequired = axios.create({
  baseURL: `${baseURL}/festivals`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const getData = async <T extends unknown>(promise: Promise<AxiosResponse<T>>) => {
  const { data } = await promise;
  return data;
};

const festivalRequired = (baseURL: string, url: string) => {
  if (baseURL.endsWith('/festivals')) {
    throw new Error(`Can't run ${url} before getFestival`);
  }
};

apiWithFestivalRequired.interceptors.request.use((config) => {
  festivalRequired(config.baseURL!, config.url!);
  return config;
});

export const use = (
  type: 'request' | 'response',
  onFulfilled?: (
    value: AxiosRequestConfig<any>,
  ) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>,
  onRejected?: (error: any) => any,
): [number, number] => {
  return [
    api.interceptors[type].use(onFulfilled, onRejected),
    apiWithFestivalRequired.interceptors[type].use(onFulfilled, onRejected),
  ];
};

export const eject = (
  type: 'request' | 'response',
  [apiId, festivalRequiredId]: [number, number],
) => {
  api.interceptors[type].eject(apiId);
  apiWithFestivalRequired.interceptors[type].eject(festivalRequiredId);
};

export const getRestaurant = (restaurantId: string) =>
  getData(api.get<ResourceDetails<Restaurant>>(`/restaurants/${restaurantId}`));

export const getFestival = async (domain: string) => {
  const { data } = await api.get<ResourceList<Festival, Supporter>>('/festivals', {
    params: { by_domain: domain },
  });

  const [festival] = data.data;
  apiWithFestivalRequired.defaults.baseURL = `${baseURL}/festivals/${festival.id}`;

  return { data: festival, included: data.included };
};

export const VoteMeal = (params: MealVote) =>
  getData(apiWithFestivalRequired.post('/votes', params));

export const getMeals = () =>
  getData(apiWithFestivalRequired.get<ResourceList<Meal, Restaurant>>('/meals'));

export const getRestaurants = () =>
  getData(apiWithFestivalRequired.get<ResourceList<Restaurant>>('/restaurants'));

export const getContent = (category: ContentCategory) =>
  getData(
    apiWithFestivalRequired.get<ResourceList<Content>>('/page_contents', {
      params: { of_type: category },
    }),
  );

export const getMealsByRestaurant = (restaurantId: string) =>
  getData(
    apiWithFestivalRequired.get<ResourceList<Meal, Restaurant>>('/meals', {
      params: { by_restaurant: restaurantId },
    }),
  );

export const getAttractions = () =>
  getData(apiWithFestivalRequired.get<ResourceList<Attraction>>('/attractions?ordering=event_date:asc,event_time:asc'));

export const getQuestions = () =>
  getData(
    apiWithFestivalRequired.get<ResourceList<Question>>('/questions', {
      params: { ordering: 'order:asc' },
    }),
  );

export const getBanners = () =>
  getData(apiWithFestivalRequired.get<ResourceList<Banner>>('/banners'));
