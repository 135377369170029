export interface BaseResource {
  id: string;
  type: string;
}

export interface Resource<T> extends BaseResource {
  attributes: T;
  relationships: Record<string, { data: BaseResource }>;
}

export interface ResourceDetails<T, R = undefined> {
  data: Resource<T>;
  included: Resource<R>[];
}

export interface ResourceList<T, R = undefined> {
  data: Resource<T>[];
  included: Resource<R>[];
}

export interface Festival {
  name: string;
  domain: string;
  first_color: string;
  second_color: string;
  third_color: string;
  fourth_color: string;
  logo: string;
  contest_rules: string;
  contest_start_date: string;
  contest_end_date: string;
  year_theme: string;
}

export enum SupporterCategory {
  Achievement = 'achievement',
  Sponsors = 'sponsor',
  Supporter = 'supporter',
}

export interface Supporter {
  name: string;
  category: SupporterCategory;
  link: string;
  logo: string;
}

export interface Content {
  name: string;
  category: string;
  content: string;
  created_at: string;
  image: string;
}

export interface Restaurant {
  name: string;
  opening_hours: string;
  phone: string;
  description: string;
  address: string;
  facebook: string;
  instagram: string;
  whatsapp: string;
  ifood: string;
  photo: string;
}

export interface Meal {
  name: string;
  description: string;
  how_to_order: string;
  created_at: string;
  photo: string;
}

export interface Attraction {
  name: string;
  event_date: string;
  event_time: string;
  location: string;
  address: string;
  price: string | null;
  created_at: string;
  photo: string;
}

export interface Question {
  description: string;
  order: number;
}

export interface Banner {
  image: string;
  name: string;
}

export interface Vote {
  question_id: string;
  grade: number;
  meal_id: string;
}

export interface MealVote {
  document_number: string;
  captcha: string;
  votes: Vote[];
  privacy_policy_accepted?: Boolean;
}

export enum ContentCategory {
  about,
  chef,
  attraction,
  restaurants,
  meals,
}
