import React from 'react';
import { LinkProps } from 'react-router-dom';
import { DefaultTheme } from 'styled-components';
import { AnchorWrapper, ButtonWrapper, LinkWrapper } from './styles';

export type ButtonProps = {
  $filled?: boolean;
  $color?: 'primary';
};

type Props = {
  filled?: boolean;
  color?: 'primary';
};

export type ButtonPropsWithTheme = ButtonProps & { theme: DefaultTheme };

const Button = ({
  filled,
  color,
  ...props
}: React.ComponentProps<typeof ButtonWrapper> & Props) => (
  <ButtonWrapper {...props} $filled={filled} $color={color} />
);

export const Link = ({
  filled,
  color,
  ...props
}: React.ComponentProps<typeof LinkWrapper> & Props & LinkProps) => (
  <LinkWrapper {...props} $filled={filled} $color={color} />
);

export const Anchor = ({
  filled,
  color,
  ...props
}: React.ComponentProps<typeof ButtonWrapper> & Props) => (
  <AnchorWrapper {...props} $filled={filled} $color={color} />
);

export default Button;
